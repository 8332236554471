<template>
  <div v-if="model && model.properties && model.properties.length" class="realty row">
    <div class="col-lg-10 col-md-offset-1 row">
      <div class="col-md-8 col-lg-8">
        <div class="contact_info row">
          <div class="col-md-4 col-lg-4 contact_number">
            <div>{{ getPropertyValue("Username") || "-"}}</div>
            <div>{{ getPropertyValue("Mobile") ||"-"}}</div>
            <div>{{ getPropertyValue("Phone") ||"-"}}</div>
          </div>
          <div class="col-md-8 col-lg-8 address">
            <div>{{ getPropertyValue("District") || "-"}}</div>
            <div>{{ getPropertyValue("Address") ||"-"}}</div>
            <div>{{ getPropertyValue("AdType") ||"-" }}</div>
          </div>
        </div>
        <div class="house_image">
          <div class="slider" id="main-slider">
            <div class="slider-wrapper">
              <img v-for="(image,ind) in images" :src="image" :key="ind" alt class="slide" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4">
        <div class="price">
          <span>FİYAT</span>
          <div class="house_price">{{ getPropertyValue("Price") ||"-"}} ₺</div>
        </div>
        <div class="details">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>BRÜT</td>
                <td>
                  {{getPropertyValue("Gross") ||"-"}} m
                  <sup>2</sup>
                </td>
              </tr>
              <tr>
                <td>NET</td>
                <td>
                  {{getPropertyValue("Net") ||"-"}} m
                  <sup>2</sup>
                </td>
              </tr>
              <tr>
                <td>BALKON</td>
                <td>{{getPropertyValue("Balcony") ||"-"}}</td>
              </tr>
              <tr>
                <td>ISITMA</td>
                <td>{{getPropertyValue("Heating") ||"-"}}</td>
              </tr>
              <tr>
                <td>ODA SAYISI</td>
                <td>{{getPropertyValue("RoomCount") ||"-"}}</td>
              </tr>

              <tr>
                <td>BINA YAŞI</td>
                <td>{{getPropertyValue("BuildingAge") ||"-"}}</td>
              </tr>
              <tr>
                <td>İLAN NO.</td>
                <td>{{getPropertyValue("AdNo") ||"-"}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
export default {
  name: "realty",
  props: ["data"],
  components: {},
  created() {},
  mounted() {
    this.startTimer();
  },
  methods: {
    getPropertyValue(propertyName) {
      if (!this.model) return null;
      var data = this.model.properties.filter(
        (f) => f.propertyKey === propertyName
      );
      if (!data || !data[0]) return null;
      return data[0].defaultValue.toUpperCase();
    },
    startTimer() {
      this.startSlider();
      var time = this.images.length * 7000;
      setTimeout(
        () => {
          if (!this.destroyed) store.dispatch("setNextPlaylist");
        },
        time < 30000 ? 30000 : time
      );
    },
    startSlider() {
      (function () {
        function Slideshow(element) {
          this.el = document.querySelector(element);
          this.init();
        }

        Slideshow.prototype = {
          init: function () {
            this.wrapper = this.el.querySelector(".slider-wrapper");
            this.slides = this.el.querySelectorAll(".slide");
            this.previous = this.el.querySelector(".slider-previous");
            this.next = this.el.querySelector(".slider-next");
            this.index = 0;
            this.total = this.slides.length;
            this.timer = null;

            this.action();
            this.stopStart();
          },
          _slideTo: function (slide) {
            var currentSlide = this.slides[slide];
            currentSlide.style.opacity = 1;

            for (var i = 0; i < this.slides.length; i++) {
              slide = this.slides[i];
              if (slide !== currentSlide) {
                slide.style.opacity = 0;
              }
            }
          },
          action: function () {
            var self = this;
            self.timer = setInterval(function () {
              self.index++;
              if (self.index == self.slides.length) {
                self.index = 0;
              }
              self._slideTo(self.index);
            }, 7000);
          },
          stopStart: function () {
            var self = this;
            self.el.addEventListener(
              "mouseover",
              function () {
                clearInterval(self.timer);
                self.timer = null;
              },
              false
            );
            self.el.addEventListener(
              "mouseout",
              function () {
                self.action();
              },
              false
            );
          },
        };

        new Slideshow("#main-slider");
      })();
    },
  },
  data() {
    return {
      destroyed: false,
    };
  },
  computed: {
    model() {
      return this.$store.state.realty;
    },
    imagePath() {
      return `${process.env.VUE_APP_BASE_CDN_URL}/${this.logo}`;
    },
    logo() {
      return this.$store.state.logo;
    },
    images() {
      return this.model.files.map(
        (file) => `${process.env.VUE_APP_BASE_CDN_URL}/${file.fileName}`
      );
    },
  },
  beforeDestroy() {
    this.destroyed = true;
  },
};
</script>

<style lang="scss" scoped>
.realty {
  background-color: white;
  background-image: url("/images/bg/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100% - 55px);
  position: fixed;
  width: 100vw;
  margin: 0;
  padding-left: 25px;
  > div {
    height: 100%;
    margin: auto;
    > div {
      height: 100%;
      margin-top: 25px;
    }
  }

  .price {
    height: 28%;
    padding: 45px 10px;
    text-align: center;
  }

  .price > span {
    font-size: 55px;
    border: 1px solid gray;
    border-radius: 65px;
    padding: 15px 55px;
    font-family: Montserrat-Bold;
  }

  .house_price {
    font-size: 70px;
    color: black;
    margin-top: 35px;
    font-family: Montserrat-Black;
    overflow: hidden;
    height: 128px;
  }

  .details {
    height: 75%;
    padding: 10px;
  }

  .details td {
    padding: 15px !important;
    height: 82px;
    vertical-align: middle !important;
    font-size: 25px;
    font-weight: 100;
    font-family: Montserrat-regular;
    border-color: #565656 !important;
  }

  table {
    margin-left: 20px;
    max-width: 85% !important;
    margin: auto !important;
  }

  .details td:nth-child(even) {
    font-weight: 600;
    font-family: Montserrat-bold;
  }

  .contact_info {
    height: 28%;
  }

  .contact_info img {
    border-radius: 50%;
    width: 80%;
    height: 80%;
    max-width: 250px;
    max-height: 250px;
    vertical-align: middle;
    border: 7px solid #3fd3ff;
    margin-top: 25px;
  }

  .house_image {
    min-height: 400px;
    height: 65%;
    border: 15px solid #2cd6d6;
    margin-top: 5px;
  }

  .house_image > img {
    height: 100%;
    width: 100%;
  }

  .pull-left {
    padding: 0 0 0 100px;
  }

  .contact_info > div {
    padding: 10px;
    text-align: left;
  }

  .contact_number > div {
    padding: 0px;
    font-size: 42px;
    margin-top: 27px;
    font-weight: 800;
    text-align: center;
    font-family: Montserrat-Regular;
    height: 60px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .contact_number > div:not(:first-child) {
    border: 3px solid white;
    border-radius: 10px;
    padding: 0px;
    font-size: 35px;
    letter-spacing: 5px;
  }

  .address > div {
    height: 25%;
    margin-top: 15px;
    text-align: center;
    font-size: 47px;
    font-weight: 900;
    font-family: Montserrat-light;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .address > div:first-child {
    font-family: Montserrat-Bold;
    font-size: 75px;
    margin-top: 0px;
    height: 99px;
    padding: 0;
    overflow: hidden;
  }
  .address > div:nth-child(2) {
    font-size: 55px;
    margin-top: 7px;
    margin-bottom: 0;
    height: 80px;
    font-family: Montserrat-light;
  }

  .address > div:last-child {
    border: 5px solid gray;
    border-radius: 15px;
    font-size: 45px;
    height: 30%;
    font-family: Montserrat-Bold;
    margin-top: 0px;
  }

  .slider {
    width: 100%;
    margin: 0;
    height: 100%;
  }

  .slider-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .slide {
    float: left;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s linear;
  }

  .slider-wrapper > .slide:first-child {
    opacity: 1;
  }
}
</style>
